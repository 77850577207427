import { useEffect, useRef, useState } from "react";
import { GuardStatusService } from "services/GuardStatusService";
import useWindowSize from "./useWindowSize";
import { WSService } from "services/WSServices";
import sound from "assets/sound/alarm.mp3";
import { useStore } from "store/context";
import useDebounce from "./useDebounce";
import { formatDateToYyyyMmDd } from "helpers/Parsers";
import { DeviceService } from "services/DeviceService";

const PAGE_SIZE_ALERTS = 10;
const AUDIO_ALARM = new Audio(sound);

export const useAlerts = () => {
  const { isMobile } = useWindowSize();
  const [{ alertList }, dispatch] = useStore();

  const [isLoading, setIsLoading] = useState(true);

  const [alerts, setAlerts] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [totalAlerts, setTotalAlerts] = useState(0);
  const [alertSelected, setAlertSelected] = useState(null);

  const [newAlert, setNewAlert] = useState(null);
  const [viewModalNewAlert, setViewModalNewAlert] = useState(false);
  const [viewModalAlerts, setViewModalAlerts] = useState(false);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [filters, setFilters] = useState({
    devices: [],
    status: ["ALARM"],
    dateRange: { start: formatDateToYyyyMmDd(new Date()), end: formatDateToYyyyMmDd(new Date()) }
  }); // mobile
  const [selectionFilterData, setSelectionFilterData] = useState(null); // desktop
  const debouncedReloadAlerts = useDebounce(selectionFilterData, 700);

  // true === ASC && false === DESC
  const [alertOrder, setAlertOrder] = useState({
    created_at: false,
    device__name: false,
    device__location__name: false,
    status: false
  });

  const getOrderingString = Object.entries(alertOrder)
    .map(([key, value]) => (!value ? "" : key))
    .filter(Boolean)
    .join(",");

  const createEventOrder = field => () =>
    setAlertOrder(prev => ({
      ...prev,
      [field]: !alertOrder[field]
    }));

  const reloadAlerts = async ({
    page = currentPage,
    globalDevices = selectionFilterData?.devices || [],
    status = selectionFilterData?.status || null,
    dateRange = selectionFilterData?.dateRange || null
  } = {}) => {
    if (!isMobile) {
      setIsLoading(true);
    }

    const params = {
      device_id: globalDevices,
      page_size: PAGE_SIZE_ALERTS,
      page: page || 1
    };

    // desktop filters
    if (Boolean(getOrderingString)) {
      params.ordering = getOrderingString;
    }

    if (status) {
      params.status = status.join("|");
    }

    if(!status && !isMobile) {
      params.status = "ALARM"
    }

    if (dateRange) {
      params.from_date = dateRange.start;
      params.from_date_to = dateRange.end;
    }

    // mobile filters
    if(isMobile) {
      if (filters?.devices && filters.devices.length > 0) {
        params.device_id = filters.devices.join(",");
      }
      
      if (filters?.status && filters.status.length > 0) {
        params.status = filters.status.join("|");
      }
      
      if (filters?.dateRange) {
        params.from_date = filters?.dateRange.start;
        params.from_date_to = filters?.dateRange.end;
      } else {
        params.from_date = formatDateToYyyyMmDd(new Date());
        params.from_date_to = formatDateToYyyyMmDd(new Date());
      }
    }

    if(params.device_id.length === 0) {
      await DeviceService.readersDevices({
        no_page: 1,
        enabled: true,
        includeType: "LOSS_PREVENTION"
      }).then(devices => {
        params.device_id = devices.map(d => d.id).join(",")
      })
    }

    GuardStatusService.getAlarmsGuardStatus(params)
      .then(response => {
        if (isMobile) {
          if(page === 1) {
            setAlerts(response?.results)
          } else {
            setAlerts(prev => [...prev, ...(response?.results || [])]);
          }
        } else {
          setAlerts(response?.results || []);
          setLastUpdate(
            response?.results.length > 0
              ? new Date(
                  response?.results[response?.results.length - 1].created_at
                )
              : null
          );
          setTotalPages(Math.ceil(response?.count / PAGE_SIZE_ALERTS) || 1);
        }

        setTotalAlerts(response?.count || 0);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchMoreData = () => {
    if (totalPages === currentPage) {
      setHasMore(false);
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  const receiveSelection = (data, type) => {
    if (data) {
      setCurrentPage(1);
      const currentPageAfterSelection = null;

      let payload = { page: currentPageAfterSelection };

      switch (type) {
        case "devices":
          payload.globalDevices = data.devices;
          break;
        case "status":
          payload.status = data.status;
          break;
        case "dateRange":
          payload.dateRange = data.dateRange;
          break;
        default:
          return;
      }

      setSelectionFilterData(prev => ({ ...prev, ...payload }));
    }
  };

  // function debounce reaload when change filters desktop
  useEffect(() => {
    if (debouncedReloadAlerts) {
      reloadAlerts(debouncedReloadAlerts);
    }
  }, [debouncedReloadAlerts]);

  // function reaload when change filters mobile
  useEffect(() => {
    if(isMobile || filters.dateRange) {
      setIsLoading(true);
      setHasMore(true);

      setAlerts([]);
      setCurrentPage(1);

      reloadAlerts({ page: 1 });
    }
  }, [filters]);

  useEffect(() => {
    if (totalAlerts && isMobile) {
      setTotalPages(Math.ceil(totalAlerts / 10));
    }
  }, [totalAlerts]);

  useEffect(() => {
    if (alerts.length > 0) {
      reloadAlerts(isMobile ? {} : { ...selectionFilterData, page: currentPage });
    }
  }, [currentPage, alertOrder]);

  // listen devices - connection web-socket
  const ws = useRef(null);

  useEffect(() => {
    ws.current = WSService.getInstance();

    const receiveEvent = payload => {
      if (
        payload.method === "GUARD" &&
        payload.room &&
        ws.current.inRoom(payload.room)
      ) {
        dispatch({
          type: "setAlert",
          payload: {
            viewNotification: true,
            onClick: () => {
              setIsLoading(true);

              GuardStatusService.getAlarmGuardStatusById(
                payload.params.guard_tag
              )
                .then(response => {
                  setAlertSelected(response);
                })
                .catch(() => {
                  setIsLoading(false);
                })
                .finally(() => {
                  setIsLoading(false);
                  dispatch({ type: "setAlert", payload: null });
                });
            }
          }
        });

        AUDIO_ALARM.play();
        dispatch({
          type: "setAlertList",
          payload: [...alertList, payload.params.guard_tag]
        });

        setTimeout(() => {
          dispatch({ type: "setAlert", payload: null });
        }, 10000);
      }
    };

    ws.current.listen("message", receiveEvent);

    return () => {
      ws.current.removeListener("message");
    };
  }, [alertList]);

  return {
    alerts: {
      list: alerts,
      total: totalAlerts,
      selected: alertSelected,
      setSelected: setAlertSelected,
      new: newAlert,
      lastUpdate
    },
    fetch: {
      more: fetchMoreData,
      reload: reloadAlerts,
      isLoading,
      setIsLoading
    },
    order: {
      state: alertOrder,
      createEvent: createEventOrder
    },
    filters: {
      list: filters,
      set: setFilters,
      receiveSelection
    },
    pagination: {
      totalPages,
      currentPage,
      setCurrentPage,
      hasMore
    },
    modals: {
      alerts: { view: viewModalAlerts, setView: setViewModalAlerts },
      newAlert: { view: viewModalNewAlert, setView: setViewModalNewAlert }
    }
  };
};
